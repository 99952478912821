/* css */
.component__backdrop {
  background-color: rgba(0, 0, 0, 0.37);
  -webkit-border-radius: 8px;
  border-radius: 8px;
}
.guess__work-main {
  position: relative;
  overflow: hidden;
}

.guess__work-main-mid {
  margin: 0 12px;
}

.guess-work .guess__work-block {
  position: relative;
  font-size: calc(12px + var(--font-increase-px));
  line-height: calc(16px + var(--font-increase-px));
  padding-bottom: 30px;
  /* padding-top: 30px; */
}
.guess-work .guess__work-block img {
  /* -webkit-border-top-left-radius: 8px;
    border-top-left-radius: 8px;
    -webkit-border-top-right-radius: 8px;
    border-top-right-radius: 8px; */
}
.guess-work .component__backdrop {
  padding: 24px 12px;
  -webkit-border-top-left-radius: 0px;
  border-top-left-radius: 0px;
  -webkit-border-top-right-radius: 0px;
  border-top-right-radius: 0px;
}
.guess__work-block-title {
  font-size: calc(14px + var(--font-increase-px));
  line-height: calc(19px + var(--font-increase-px));
}
.guess-work .guess__work-block .separator {
  border: 1px solid #ededed;
  opacity: 0.13;
  margin-bottom: 24px;
}
.guess__work-main-mid .btn.incorrect,
.guess__work-main-mid .btn.correct {
  position: relative;
  padding-left: 24px;
  padding-right: 24px;
}
.guess__work-main-mid .btn.incorrect::before,
.guess__work-main-mid .btn.correct::before {
  position: absolute;
  content: "";
  top: 50%;
  left: 14px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  line-height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #ffffff;
}
.guess__work-main-mid .btn.incorrect.icon__float-right::before {
  left: auto;
  right: 10px;
  top: 19px;
}

.guess__work-main-mid .btn {
  width: 100%;
  text-align: center;
  max-width: 100%;
  display: inline-block;
  margin-top: 25px;
}

.interstitial-select:focus {
  outline: none;
}

.interstitial-wrapper {
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
}

.interstitial-wrapper .radio-item-container {
  display: inline-block;
  position: relative;
  cursor: pointer;
  margin-right: 25px;
}

.interstitial-wrapper .select-container {
  margin-top: 11px;
}

.interstitial-wrapper p {
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  margin-bottom: 0;
}

.interstitial-wrapper .radio-item-container {
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.interstitial-wrapper input[type="radio"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  left: 0;
}

.interstitial-wrapper .checkmark {
  float: left;
  height: 20px;
  width: 20px;
  margin-right: 12px;
  background-color: transparent;
  border: 3px solid #5fc4b8;
  border-radius: 50%;
}

.interstitial-wrapper .radio-item-container input:checked ~ .checkmark {
  background-color: #5fc4b8;
}

.interstitial-wrapper .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.interstitial-wrapper .radio-item-container input:checked ~ .checkmark:after {
  display: block;
}

.percentage__center {
  position: absolute;
  width: 48.5%;
  background: #fff;
  display: inline-block;
  left: 0;
  right: 0;
  padding: 10px 15px;
  border-radius: 25px;
  margin: 0 auto;
  max-width: 220px;
  top: 89%;
  font-size: calc(20px + var(--font-increase-px));
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

div.component__backdrop.backdrop__bottom-rounded p {
  margin: 0;
}

.selectContainer {
  margin-top: 10px;
}

@media only screen and (min-width: 375px) {
  .guess__work-main-mid {
    max-width: 360px;
    min-width: 351px;
    margin: 0 auto;
  }
}
