form.stepByStep {
  padding-bottom: 20px;
}

.stepByStepWrapper {
  width: inherit;
}

form.stepByStep {
  height: 47px;
  margin-bottom: 5px;
  display: flex;
}

form.stepByStep input {
  height: 40px;
  max-width: 265px;
  width: 90%;
  left: 24px;
  top: 1421px;
  background: #000000;
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-sizing: border-box;
  border-radius: 5px;
  margin-right: 14px;
  color: #ffffff;
  padding: 1rem 0.5rem;
  font-size: 18px;
}

.stepsContainer {
  text-align: center;
  margin-top: 0px;
  min-height: 65px;
  overflow: hidden;
  padding: 20px 20px 0px 20px;
  margin-bottom: 20px;
  background-color: #000000 !important;
  overflow: auto;
}

/* Positions the button to be inside the input */
.stepInputBtn {
  position: relative;
  height: 40px;
  width: 40px;
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0px;
  text-align: center;
  padding: 0px !important;
}

.stepByStepText {
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  margin-bottom: 24px;
}

.wolframAlphaLogo {
  height: 20px;
}

.linkWrapper {
  width: 100%;
  height: 30px;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #5fc4b8;
}

.linkWrapper a {
  text-decoration-line: underline !important;
}

.linkWrapper a:disabled {
  color: #5fc4b8;
  opacity: 0.3;
}

.linkWrapper a:hover {
  color: #b8fff7;
}

.linkWrapper a:active {
  color: #b8fff7;
  opacity: 0.3;
}

@media (max-width: 920px) {
  form.stepByStep input {
    min-width: auto;
  }
}

@media (max-width: 768px) {
  form.stepByStep input {
    min-width: auto;
  }
}

.modal-btn {
  display: grid;
}

.stepsContainer .MathJax_SVG svg {
  height: auto;
  vertical-align: super !important;
}

.solutionImg {
  margin-bottom: 20px;
  filter: invert(1);
}
