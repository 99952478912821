.revealed-answer-box {
  flex: 0.95;
  margin-bottom: 12px !important;
}

.revealed-answer-box:last-child {
  margin-bottom: 0px !important;
  line-height: 21.6px;
}

.show-feedback-answer-title {
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 1px;
  margin-bottom: 6px;
  color: #c1cfd9;
  text-transform: uppercase;
}

.answer-status {
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #ffffff;
  margin-left: 12px;
}

.revealed-answer-box div.paragraph {
  margin-bottom: 0rem;
  font-size: 18px;
  line-height: 21.6px;
}

div.lesson-list.revealed-answer-wrapper-feedback {
  height: auto;
  max-height: 100%;
  width: auto;
  background-color: #0e0e0f;
  margin-left: 4px;
}

div.lesson-list.revealed-answer-wrapper-feedback div:last-child {
  margin-bottom: 0px !important;
}

div.lesson-list.revealed-answer-wrapper-feedback > div.solution-text {
  overflow: auto;
  width: inherit;
}

.active__learning-block-title {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 12px;
}

.match-text {
  color: #fff;
}
.show-solution {
  color: var(--teal) !important;
  margin-left: auto;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  margin-top: 2px;
  line-height: 17px;
  letter-spacing: 1px;
  text-align: right;
  cursor: pointer;
}
.hide-solution {
  float: right;
}

.show-solution:hover {
  color: var(--white);
}
.correct-answer {
  font-size: 18px;
  font-weight: 300;
}
.correct-heading {
  margin-bottom: 12px !important;
}
.explanation-heading {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #b1bfc5;
  margin-bottom: 12px;
}
