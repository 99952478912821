.active__learning-progress {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.active__learning-progress-track {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-left: 22px;
  position: relative;
}

.active__learning-progress-label {
  font-size: calc(10px + var(--font-increase-px));
}

.active__learning-progress-track .progress__radio {
  position: relative;
  width: 22px;
  height: 22px;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  background-color: #5e636b;
  margin-right: 10px;
  cursor: pointer;
}

.active__learning-progress-track .progress__radio:last-child {
  margin-right: 0;
}
.active__learning-progress-track .progress__radio.completed {
  position: relative;
  background-color: #5fc4b8;
}
.active__learning-progress-track .progress__radio.completed:after {
  position: absolute;
  right: 2px;
  top: 0px;
  content: "";
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #ffffff;
  -webkit-mask: url("/images/icons/icon-checkmark.svg") no-repeat 1px 0;
  mask: url("/images/icons/icon-checkmark.svg") no-repeat 1px 0;
}
.active__learning-progress-track .progress__radio.active:before {
  position: absolute;
  left: 8px;
  top: 8px;
  content: "";
  width: 6px;
  height: 6px;
  background-color: #5fc4b8;
  display: inline-block;
  line-height: calc(6px + var(--font-increase-px));
  -webkit-border-radius: 100%;
  border-radius: 100%;
}

.active__learning-progress-track .bubble__text-indicator {
  position: absolute;
  left: -12px;
  text-align: center;
  bottom: 24px;
  font-size: calc(4px + var(--font-increase-px));
  font-weight: 600;
  color: #fafafa;
  white-space: pre-line;
  line-height: 13px;
  pointer-events: none;
}

.active__learning-progress-track .bubble__text-indicator.text-pop {
  bottom: 22px;
}

.active-learning .component__backdrop {
  padding: 40px;
  position: relative;
}

.active-learning .review-mode.component__backdrop {
  position: absolute;
}

.active__learning-block-title {
  font-size: calc(14px + var(--font-increase-px));
  line-height: calc(19px + var(--font-increase-px));
}

.active__learning-hr {
  position: relative;
  left: -4%;
  width: -webkit-calc(100% + 8%);
  width: calc(100% + 8%);
  border-color: rgba(233, 233, 233, 0.13);
}

.active-learning .active__learning-block {
  font-size: calc(12px + var(--font-increase-px));
  line-height: calc(16px + var(--font-increase-px));
  font-weight: 300;
  padding-bottom: 30px;
  padding-top: 30px;
  border-radius: 8px;
}

.active-learning .active__learning-block.d-flex .custom-radio {
  margin: 0.125rem;
}

.active__learning-options {
  overflow: hidden;
}

.active__learning-main-mid .active__learning-options .btn {
  width: 177px;
  max-width: 100%;
  display: inline-block;
  white-space: normal;
  background-color: #212c2e;
}
#multiple_checkbox_auto {
  width: auto;
  overflow-x: auto;
}
#multiple_checkbox_auto .custom-checkbox > label {
  height: 100%;
  line-height: 1.5;
  padding: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: space-around;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 98%;
}

#multiple_checkbox_auto .btn-secondary,
#multiple_radio_auto .btn-secondary {
  color: #fff;
  border: none;
  text-transform: none;
  font-size: calc(12px + var(--font-increase-px));
  letter-spacing: 0;
}

#multiple_checkbox_auto .btn-secondary:hover,
#multiple_radio_auto .btn-secondary:hover {
  color: #fff;
  background-color: #4a5759;
  border-color: #445052;
}

#multiple_radio_auto {
  width: auto;
}
#multiple_radio_auto .custom-radio > label {
  height: 100%;
  line-height: 1.5;
  padding: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.active__learning-options .btn {
  float: left;
}

.active__learning-options .btn:nth-child(-n + 2) {
  margin-bottom: 8px;
}
.active__learning-options .btn:nth-child(odd) {
  margin-right: 8px;
}

.active__learning-main-mid .incorrect {
  position: relative;
  padding-right: 24px;
}

.active-learning {
  margin-bottom: 40px;
}

.adaptation-statement div.paragraph {
  margin: 0px;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #98a4ae;
}

.adaptation-statement a {
  text-decoration: none;
  color: #77b1af;
}

.active__learning-pagination.adaptation {
  background: none !important;
  padding-top: 0px !important;
  margin-top: 36px !important;
}

.active__learning-pagination .btn,
.active__learning-options .btn,
.active-learning__floats .btn {
  font-size: 14px;
  letter-spacing: 1px;
  line-height: calc(35px + var(--font-increase-px));
  padding: 0 8px;
}

.active__learning-options .btn-secondary {
  background: rgba(255, 255, 255, 0.037);
  border-color: rgb(18, 26, 28);
}

.active__learning-pagination .btn-secondary:disabled {
  background-color: #161618;
  border-color: #77b1af;
}

.active__learning-options .btn-secondary:hover,
.active__learning-options .btn-secondary:active,
.active__learning-options .btn-secondary:focus {
  background: #445052;
}

.active__learning-main-mid .active__learning-pagination .btn {
  width: 150px;
  margin: 6px auto;
}

.active-learning__floats {
  position: absolute;
  top: 0;
  width: 200px;
}
.active-learning__floats .component__backdrop {
  width: 315px;
  max-width: 100%;
  max-height: 550px;
  overflow-y: auto;
}
/* .active-learning__floats .component__backdrop #react-mathjax-preview{
    overflow-y: auto;
    max-height: 450px;
} */
.active-learning__floats .active__learning-block-title {
  margin-top: 15px;
  margin-bottom: 15px;
}

.active-learning__floats div.paragraph,
.active-learning__floats p {
  font-size: calc(12px + var(--font-increase-px));
  line-height: calc(16px + var(--font-increase-px));
}

.active-learning__floats.floats__left {
  left: -5px;
  position: fixed;
  top: 150px;
}
.active-learning__floats.floats__right {
  right: -5px;
  position: fixed;
  top: 150px;
}

.active-learning__guesswork-answer {
  margin-top: 24px;
  margin-left: -50px;
  max-width: calc(100% + 50px) !important;
  padding-left: 55px !important;
}
.active-learning__guesswork-answer .radio__badge {
  position: absolute !important;
  left: 10px;
}

.active__learning-blank {
  background: transparent;
  border: 0;
  border-bottom: 1px solid #78bcb8;
  display: inline-block;
  margin-left: 2px;
  margin-right: 2px;
  color: #fff;
  min-width: 24px;
  margin-bottom: 0;
  height: 20px;
  text-align: center;
}

.active__learning-blank:active,
.active__learning-blank:focus {
  outline: none;
  border-bottom: 1px solid #fff;
}

.active-learning__floats .btn {
  min-width: 135px;
  margin-top: 12px;
}
.active__learning-progress-track .progress__radio.incorrect {
  position: relative;
  background-color: #e1774f;
}

.active__learning-progress-track .progress__radio.guesswork {
  position: relative;
  background-color: #7f8e91;
}
.active__learning-progress-track .progress__radio.incorrect:after {
  position: absolute;
  right: 2px;
  top: 0px;
  content: "";
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #ffffff;
  -webkit-mask: url(/images/icons/icon-cross-sm.svg) no-repeat 1px 0;
  mask: url(/images/icons/icon-cross-sm.svg) no-repeat 1px 0;
}

.active__learning-progress-track .progress__radio.guesswork:after {
  position: absolute;
  left: 3px;
  top: 3px;
  content: "";
  width: 15px;
  height: 15px;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #ffffff;
  -webkit-mask: url(/images/icons/icon-three-dots-for-guesswork.svg) no-repeat
    1px 0;
  mask: url(/images/icons/icon-three-dots-for-guesswork.svg) no-repeat 1px 0;
}
.response-submitted-popup {
  width: 60%;
  height: 85%;
  fill: white !important;
}

.active__learning-progress-track .progress__radio.response-submitted {
  position: relative;
  background-color: #7f8e91;
}

.active__learning-progress-track .progress__radio.response-submitted:after {
  position: absolute;
  left: 2px;
  top: 3px;
  content: "";
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-color: white;
  -webkit-mask: url(/images/icons/response-submitted.svg) no-repeat;
  mask: url(/images/icons/response-submitted.svg) no-repeat;
}

/* .custom-control-input:checked + label.btn, .custom-control-input:hover + label.btn {
    background: #445052;
} */

.custom-control-input:hover + label.btn {
  background: #445052;
}

.custom-checkbox,
.custom-radio {
  position: relative;
}
.custom-control-input {
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 0;
}

#studentAnswer {
  width: 100%;
  bottom: 60px;
}

#studentAnswer.studentAnswerReady {
  visibility: visible;
}

#studentAnswer.studentAnswerLoading {
  visibility: hidden;
}

#studentAnswer div.wiriseditorwrapper {
  max-width: 100%;
}

#studentAnswer div.wiriseditorwrapper .wrs_editor .wrs_formulaDisplay {
  background-color: rgb(18, 37, 41) !important;
  border: none !important;
  height: 150px !important;
}

.wrs_container span,
.wrs_container svg {
  color: #fff !important;
}

.wrs_container span .wrs_notItalic .wrs_notBold .wrs_font_inherit,
.wrs_container svg polyline {
  stroke: rgb(255, 255, 255) !important;
}

.wrs_editor .wrs_formulaDisplay.wrs_focused .wrs_caret {
  border-left: 1px solid white !important;
}

.wrs_editor .wrs_formulaDisplay.wrs_focused .wrs_caret.wrs_light {
  border-left-color: #fff !important;
}

.wrs_editor .wrs_formulaDisplay.wrs_focused .wrs_caret.wrs_dark {
  border-left-color: white !important;
}

.wrs_editor .wrs_formulaDisplay.wrs_focused .wrs_underlineCaret {
  border-bottom: 1px solid white !important;
}

.wrs_editor .wrs_formulaDisplay.wrs_focused .wrs_underlineCaret.wrs_light {
  border-bottom-color: white !important;
}
.wrs_editor .wrs_formulaDisplay.wrs_focused .wrs_underlineCaret.wrs_dark {
  border-bottom-color: white !important;
}

.wrs_editor .wrs_formulaDisplay .wrs_selection {
  background-color: transparent !important;
}

.wrs_editor .wrs_formulaDisplay .wrs_error {
  background-color: transparent !important;
}
.wrs_editor .wrs_formulaDisplay .wrs_container span {
  border-top-color: #fff !important;
}
.wrs_editor .wrs_image,
.wrs_hand .wrs_toolbar img {
  vertical-align: middle;
  filter: brightness(0) invert(1);
}
.wrs_editor td {
  background: #253235;
  border: 2px solid #0e1d22;
  border-radius: 10px;
  width: 75px;
  height: 40px;
}
.wrs_editor td:hover,
.wrs_editor td:active,
.active__learning-block
  .wrs_editor.wrs_withHand:not(.wrs_handDisabled):not(.wrs_toolbarHidden)
  .wrs_handWrapper
  > input:focus,
.active__learning-block
  .wrs_hand
  .wrs_toolbar
  .wrs_button:hover:not([disabled]) {
  background-color: #445052;
  border-color: #3e494b;
}
.active__learning-block .wrs_editor .wrs_panelContainer {
  background: #0e1d21;
  border-color: #0e1d21;
}
.active__learning-block .wrs_editor .wrs_button:hover,
.wrs_editor .wrs_button:focus {
  border-color: transparent !important;
}
.wrs_editor .wrs_toolbar .wrs_noTabs .wrs_linkButton {
  display: none;
}
.active__learning-block
  .wrs_editor.wrs_withHand:not(.wrs_handDisabled):not(.wrs_toolbarHidden)
  .wrs_handWrapper
  > input {
  background-color: #253235;
  border-color: #253235;
  height: 152px;
}
.wrs_editor .wrs_layoutFor1Rows .wrs_button:active,
.wrs_editor .wrs_layoutFor1Rows .wrs_button:focus,
.wrs_hand .wrs_toolbar .wrs_button:active:not([disabled]),
.wrs_hand .wrs_toolbar .wrs_button.wrs_pressed:not([disabled]) {
  background: none !important;
  outline: 0 !important;
}
div.wirisanswerfielddecoration.wirisembeddeddecoration,
div.wirisanswerfielddecoration.wiriseditordecoration div.wiriseditorwrapper {
  padding-right: 0 !important;
  background-image: none !important;
}
#studentAnswer .wrs_editor .wrs_button.wrs_disabled:focus,
#studentAnswer .wrs_editor .wrs_comboButton.wrs_disabled:focus {
  border-color: transparent !important;
}
.active__learning-block .wrs_editor .wrs_toolbar {
  display: block !important;
  height: 46px !important;
}
#studentAnswer .wrs_editor .wrs_focusElementContainer {
  width: inherit !important;
  height: inherit !important;
}
.wrs_hand .wrs_canvasContainer {
  background: #253235 !important;
}
.wrs_hand .wrs_toolbar,
.wrs_editor.wrs_withHand.wrs_handOpen:not(.wrs_handDisabled):not(.wrs_toolbarHidden)
  .wrs_handWrapper
  > input {
  background-color: #253235 !important;
  border-color: #253235 !important;
}
.wrs_hand .wrs_canvasContainer .wrs_canvas {
  border-color: #0e1d21 !important;
}
p.font__md,
#react-mathjax-preview-result > div.paragraph > div.single_equation > span,
#react-mathjax-preview-result > p > span {
  font-size: calc(16px + var(--font-increase-px)) !important;
  line-height: calc(21px + var(--font-increase-px)) !important;
}
#react-mathjax-preview-result h3 {
  font-weight: 300;
  font-size: 24px;
  line-height: 29px;
}
p.font__md,
#react-mathjax-preview-result span.custom-tooltip {
  color: #008080;
  font-size: 1em !important;
  border-bottom: dotted;
  cursor: pointer;
}

.image__attr span {
  background-color: #000000;
  color: rgba(255, 255, 255, 0.6);
  display: block;
  padding: 5px;
  text-align: right;
  font-size: calc(8px + var(--font-increase-px));
}
.image__attr span:empty {
  display: none;
}
.image__attr {
  margin-top: 5px;
  cursor: zoom-in;
}
.lesson-list .radio__badge.radio__badge-md {
  margin-right: 0.31rem;
  min-width: 33px;
}
.MathJax .math > span {
  font-size: 136% !important;
  font-size: calc(1rem + var(--font-increase-rem)) !important;
}
.lesson-list .MathJax .math > span {
  font-size: inherit !important;
}
.editor-result h1,
.font__md h1 {
  font-size: calc(50px + var(--font-increase-px));
}
.editor-result h2,
.font__md h2 {
  font-size: calc(40px + var(--font-increase-px));
}
.editor-result h3,
.font__md h3 {
  font-size: calc(30px + var(--font-increase-px));
}
.editor-result h4,
.font__md h4 {
  font-size: calc(16px + var(--font-increase-px)) !important;
  line-height: calc(21px + var(--font-increase-px)) !important;
  font-weight: bold;
}

.image__attr span {
  background-color: #000000;
  color: rgba(255, 255, 255, 0.6);
  display: block;
  padding: 5px;
  text-align: right;
  font-size: calc(8px + var(--font-increase-px));
}
.image__attr span:empty {
  display: none;
}
.image__attr span {
  background-color: #000000;
  color: rgba(255, 255, 255, 0.6);
  display: block;
  padding: 5px;
  text-align: right;
  font-size: calc(8px + var(--font-increase-px));
}
.image__attr span:empty {
  display: none;
}
.questions-btn {
  width: 150px !important;
}
.questions-btn-wrapper {
  justify-content: flex-start !important;
}

.questions-btn-wrapper > .btn {
  width: 64px !important;
}

.questions-btn-wrapper--reverse {
  flex-direction: row-reverse !important;
  justify-content: flex-end !important;
}

.questions-btn-wrapper--reverse > .btn:last-child {
  width: 64px !important;
}

@media only screen and (min-width: 1360px) {
  .active-learning__floats .component__backdrop {
    width: 360px;
    padding: 0.7em;
  }
}
#studentAnswer .wrs_editor .wrs_panelContainer > div.wrs_selected {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
#studentAnswer .wrs_editor .wrs_contextPanel .wrs_panelContainer,
#studentAnswer .wrs_editor .wrs_matrixButtonPanel {
  background-color: #0e1c21;
  padding: 5px;
  border-color: #0e1c21;
  text-align: center;
  color: #ffffff;
}
#studentAnswer .wrs_editor .wrs_matrixButtonPanel input {
  background: #253235;
  color: #fff;
  border: 0;
  text-align: center;
}
#studentAnswer .wrs_editor .wrs_contextPanel .wrs_panelContainer td {
  width: 40px;
  min-width: 40px;
  max-width: 40px;
}
.wrs_matrixButtonPanel table td {
  width: auto;
  padding: 4px;
}
#studentAnswer .wrs_matrixButtonPanel > table:first-child td {
  width: auto;
  height: auto;
  padding: 0;
}
#studentAnswer .wrs_editor .wrs_singleRowPanel .wrs_line {
  width: 0;
}
#studentAnswer
  .wrs_editor
  .wrs_toolbar
  .wrs_expandButton.wrs_expandButtonFor1RowsLayout {
  height: 38px;
  background-size: 4px;
  width: 12px;
}
@media only screen and (max-width: 1199px) {
  .active__learning-main-mid .active__learning-options .btn {
    width: 160px;
  }
  .active__learning-options.no-gutters > [class*="col-"] {
    padding: 0 5px;
  }
}

@media only screen and (max-width: 991px) {
  .active-learning .active__learning-block {
    padding: 30px 20px;
  }
  .active-learning .component__backdrop {
    padding: 20px;
    min-height: 0;
  }
  .active-learning__floats p {
    margin-bottom: 0;
  }
}

#studentAnswer .wrs_editor .wrs_button.wrs_disabled:focus,
#studentAnswer .wrs_editor .wrs_comboButton.wrs_disabled:focus {
  border-color: transparent !important;
}
#studentAnswer .wrs_editor .wrs_panelContainer > div.wrs_selected {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
#studentAnswer .wrs_editor .wrs_contextPanel .wrs_panelContainer,
#studentAnswer .wrs_editor .wrs_matrixButtonPanel {
  background-color: #0e1c21;
  padding: 5px;
  border-color: #0e1c21;
  text-align: center;
  color: #ffffff;
}
#studentAnswer .wrs_editor .wrs_matrixButtonPanel input {
  background: #253235;
  color: #fff;
  border: 0;
  text-align: center;
}
#studentAnswer .wrs_editor .wrs_contextPanel .wrs_panelContainer td {
  width: 40px;
  min-width: 40px;
  max-width: 40px;
}
.wrs_matrixButtonPanel table td {
  width: auto;
  padding: 4px;
}
#studentAnswer .wrs_matrixButtonPanel > table:first-child td {
  width: auto;
  height: auto;
  padding: 0;
}
#studentAnswer .wrs_editor .wrs_singleRowPanel .wrs_line {
  width: 0;
}
#studentAnswer
  .wrs_editor
  .wrs_toolbar
  .wrs_expandButton.wrs_expandButtonFor1RowsLayout {
  height: 38px;
  background-size: 4px;
  width: 12px;
}

/*---button color change on click for True-False and Multiple Choice*/
.active__learning-options .ans-incorrect {
  background-color: #e1774f !important;
}

.active__learning-options .ans-correct {
  background-color: #78bcb8 !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.active__learning-options .ans-correct-partial {
  background: #707c7e !important;
}

.active__learning-options .ans-correct-partial-assesment {
  border: 1px solid #ffffff !important;
}

/* .active__learning-options .ans-correct:hover,
.active__learning-options .ans-correct:active,
.active__learning-options .ans-correct:focus {
    background-color: #5eafab !important;
} */

.active__learning-options div.paragraph,
.active__learning-options p {
  margin: 0 !important;
  font-weight: normal !important;
}

.review-mode {
  position: absolute;
  right: 30px;
  top: 0;
  font-size: calc(14px + var(--font-increase-px));
}

.studentAnswerOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(150, 150, 150, 0.05);
  z-index: 3;
}
.lesson {
  position: fixed;
  top: 150px;
  z-index: 3;
  /* top: 0; */
  left: 0;
  max-height: 60%;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-transform: translateX(-275px);
  -ms-transform: translateX(-275px);
  transform: translateX(-275px);
}
.lesson .lesson-list {
  width: 430px;
  height: 98%;
  border-radius: 0;
  border-bottom-right-radius: 8px;
  color: #f1f3f3;
  font-size: calc(12px + var(--font-increase-px));
  line-height: calc(14px + var(--font-increase-px));
  position: relative;
  padding-bottom: 70px !important;
  overflow: auto;
}

.lesson-list .box {
  padding: 0 31px 0 24px;
}

/* free from css file */
.lesson .lesson-list {
  padding-bottom: 24px !important;
}

.active-que-text,
.que-text {
  position: relative;
  display: flex;
  align-items: flex-start;
}

.active-que-text > div {
  flex-grow: 1;
}

@media (max-width: 1660px) {
  /* free form css file */
  .lesson .lesson-list {
    width: 380px;
  }

  .active-learning .component__backdrop {
    padding: 15px;
  }

  .active__learning-hr {
    left: 0%;
    width: calc(100% + 0%);
  }
}
@media (max-width: 1366px) {
  /* free form css file */
  .lesson .lesson-list {
    width: 275px;
  }
}

.default-cursor {
  cursor: default !important;
}

.tab-content button:focus {
  outline: transparent;
}

.flash-start {
  opacity: 0.25;
  transition: 100ms;
}

.flash-end {
  opacity: 1;
  transition: 100ms;
}

.pending-response {
  background: #7f8e91 !important;
}

.pending-response-assessment {
  border: 1px solid #ffffff !important;
}

#touch-layout .active__learning-progress-track {
  padding-left: 0px;
}

#card-lesson-text a {
  display: inline !important;
}

.question-text-anchor-inline a {
  display: inline !important;
}

.tableWrapper {
  position: relative;
}

.tableWrapperScrollable {
  position: relative;
  overflow: auto;
  max-height: 600px;
  margin-bottom: 24px;
}

.tableWrapperScrollable::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
.tableWrapperScrollable::-webkit-scrollbar-track {
  background: #1d1d1f;
  width: 16px;
}

/* Handle */
.tableWrapperScrollable::-webkit-scrollbar-thumb {
  background: #5b6067;
  border-radius: 3px;
}

/* Handle on hover */
.tableWrapperScrollable::-webkit-scrollbar-thumb:hover {
  background: #98a4ae;
}

.tableContainer {
  overflow-x: auto;
  margin: 25px 0px 0px 0px;
}

.tableContainer::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
.tableContainer::-webkit-scrollbar-track {
  background: #1d1d1f;
  width: 16px;
}

/* Handle */
.tableContainer::-webkit-scrollbar-thumb {
  background: #5b6067;
  border-radius: 3px;
}

/* Handle on hover */
.tableContainer::-webkit-scrollbar-thumb:hover {
  background: #98a4ae;
}

.tableContainer table {
  table-layout: fixed;
  border-color: #262629;
}

.tableContainer tr {
  border-color: #262629;
  padding: 12px;
}

.tableContainer tr:nth-child(1) td,
.tableContainer tr:nth-child(1) td span,
.tableContainer tr:nth-child(1) td strong,
.tableContainer tr:nth-child(1) td h3 {
  font-size: 16px;
  line-height: 16.8px;
  letter-spacing: 1px;
  color: #fff !important;
}

.tableContainer table td {
  font-size: 16px;
  line-height: 19.2px;
  width: 137px;
  padding: 12px;
  font-weight: 400;
  color: #fff;
  overflow: hidden;
}

.tableContainerScrollable table {
  table-layout: fixed;
  border-color: #262629;
}

.tableContainerScrollable tr {
  border-color: #262629;
  padding: 12px;
}

.tableContainerScrollable tr:nth-child(1) td,
.tableContainerScrollable tr:nth-child(1) td span,
.tableContainerScrollable tr:nth-child(1) td strong,
.tableContainerScrollable tr:nth-child(1) td h3 {
  font-size: 16px;
  line-height: 16.8px;
  letter-spacing: 1px;
  color: #fff !important;
}

.tableContainerScrollable table td {
  font-size: 16px;
  line-height: 19.2px;
  width: 137px;
  padding: 12px;
  font-weight: 400;
  color: #fff;
  overflow: hidden;
}

.gradientOverlay {
  position: absolute;
  z-index: 3;
  right: 0;
  width: 20%;
  background: linear-gradient(to right, transparent, #121212);
}

.gradientLeftOverlay {
  left: 0;
  background: linear-gradient(to left, transparent, #121212);
}

.lesson_text ul {
  margin-bottom: 24px;
}

.lesson_text li {
  font-size: 18px !important;
  font-weight: 300 !important;
  line-height: 24px !important;
}

lesson_text li::marker {
  font-size: 18px !important;
}
