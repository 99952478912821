.do-icon-white-arrow {
  width: 7px;
  height: 14px;
}
.do-icon-white-arrow {
  background-image: url("/images/icons/icon-white-arrow-custom.svg") !important;
}

.guess__work-progress-track {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-left: 22px;
}

.arrow-left.disabled svg,
.arrow-right.disabled svg {
  opacity: 0.5;
}

.modal {
  background-color: rgba(0, 0, 0, 0.75);
}
.modal .question-lists {
  font-size: calc(12px + var(--font-increase-px));
  line-height: calc(16px + var(--font-increase-px));
  max-width: 670px;
  margin: 23px auto 50px;
}
.que-list img {
  max-width: 65px;
  height: auto;
}
.modal-active-learning-header {
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 1px;
  color: #c1cfd9;
  padding-top: 16px;
  padding-bottom: 27px;
}
.que-list.section-panel__list {
  margin-bottom: 12px;
  border: 3px solid #172d31;
  transition: all 0.15s ease-in-out;
  color: #fff;
  text-decoration: none !important;
  position: relative;
  padding: 12px;
}

.list_item:hover {
  background-color: #2c575e !important;
  border: 3px solid #2c575e !important;
}

.list_active {
  border-color: #77bcb7 !important;
  /* background-color:#78BCB8 !important; */
}

.que-list.section-panel__list:focus {
  border-color: #78bcb8;
}
.que-list .progress__radio {
  position: relative;
  width: 1.375rem;
  height: 1.375rem;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  background-color: #4e4f4f;
  margin: 0 13px 0 0;
}
.que-list .progress__radio.completed {
  background-color: #78bcb8;
}
.que-list .progress__radio.incorrect {
  background-color: #e1774f;
}
.que-list .progress__radio.completed:after,
.que-list .progress__radio.incorrect:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #ffffff;
}
.que-list .progress__radio.completed:after {
  left: 0.19rem;
  top: 0.375rem;
  -webkit-mask: url("/images/icons/icon-checkmark.svg") no-repeat 0.06rem 0;
  mask: url("/images/icons/icon-checkmark.svg") no-repeat 0.06rem 0;
}
.que-list .progress__radio.incorrect:after {
  left: 0.125rem;
  top: 0.19rem;
  -webkit-mask: url(/images/icons/icon-cross-sm.svg) no-repeat 0.06rem 0;
  mask: url(/images/icons/icon-cross-sm.svg) no-repeat 0.06rem 0;
}
.que-list .progress__radio.active:before {
  position: absolute;
  left: 0.5rem;
  top: 0.5rem;
  content: "";
  width: 0.375rem;
  height: 0.375rem;
  background-color: #78bcb8;
  display: inline-block;
  -webkit-border-radius: 100%;
  border-radius: 100%;
}
.modal-backdrop {
  background-color: #202020;
}
.modal-backdrop.show {
  opacity: 0.9;
}
.guess__work-progress-track {
  max-width: 530px;
}
/* slider css */
.guess__work-progress-track .slider {
  max-width: 320px;
}
.slick-next {
  right: -25px;
}
.slick-prev {
  left: -33px;
}
.slick-prev.slick-disabled,
.slick-next.slick-disabled {
  opacity: 0.3;
}
.slick-prev:before,
.slick-next:before {
  content: none;
}
.slick-prev,
.slick-next,
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  background-image: url(/images/icons/icon-prev.svg);
  background-repeat: no-repeat;
  background-position: center center;
  top: 15%;
  transform: none;
}
.slick-next,
.slick-next:hover,
.slick-next:focus {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.slider {
  position: relative;
}
.slick-slide:hover {
  text-decoration: underline;
}

.guess__work-progress-track .slick-slide {
  max-width: 32px;
}
.guess__work-progress-track .number {
  display: block;
  margin-right: 10px;
  font-size: calc(9px + var(--font-increase-px));
  font-weight: 700;
  line-height: calc(11px + var(--font-increase-px));
  margin-top: 5px;
  text-align: center;
}
.ellipses-two-text {
  position: relative;
  text-align: justify;
  padding-right: 13px;
  max-height: 33px;
  overflow: hidden;
}
.ellipses-two-text:after {
  content: "...";
  position: absolute;
  right: 0;
  bottom: 0;
}
.DescriptionExcerpt {
  display: flex;
  flex-direction: row;
}
.DescriptionExcerpt p {
  overflow: hidden;
  position: relative;
}
.DescriptionExcerpt p {
  display: -webkit-box;
  height: calc(33px + (var(--font-increase-px) * 2));
  font-weight: 300;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 0;
  overflow-wrap: anywhere;
}

.no-copy {
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Likely future */
}

.desmos_graph_div {
  height: 3.125rem;
  min-width: 6.25rem;
  margin-right: 1rem;
}
.w-question-text {
  width: calc(100% - 6.25rem);
}

#touch-layout .slick-slide {
  margin-right: 16px;
}

#touch-layout .slick-slide:nth-child(5n) {
  margin-right: 6px;
}

#touch-layout .slick-list {
  width: 226px;
  margin: 0 auto;
}

#touch-layout .slick-track {
  display: flex;
  width: 10000px !important;
}

#touch-layout .guess__work-progress-track .slider {
  width: 100%;
  margin: 0 75px;
}
#touch-layout .slick-next {
  right: -66px;
}

#touch-layout .slick-prev {
  left: -66px;
}

#touch-layout .guess__work-progress-track {
  padding-left: 0;
}

#touch-layout .guess__work-progress-track .number {
  margin-right: 0;
  margin-top: 8px;
  text-align: center;
}

.bottomSpace {
  padding-bottom: 4px;
}
