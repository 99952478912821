.do-icon-white-arrow {
  width: 5px;
  height: 10px;
}
.do-icon-white-arrow {
  background-image: url(/images/icons/icon-white-arrow.svg);
}
.lect-video-sec {
  /*overflow: hidden;*/
  padding-top: 0;
  margin-top: -30px;
  min-height: 320px;
}
.padding-bottom {
  padding-bottom: 2rem;
}
.instructor-slide {
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  padding-top: 44px;
}
.instructor-slide.on {
  height: 100%;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}
.instructor-slide .slider {
  width: 243px;
  min-height: auto;
  height: 100%;
  background-color: #3b5d5b;
  display: none;
}
.instructor-slide .slider ul {
  margin: 0;
  padding: 1rem;
}
.instructor-slide .slider ul > li {
  position: relative;
  list-style: none;
  margin-bottom: 3px;
}
.instructor-slide .slider ul > li .info {
  resize: both;
  overflow: auto;
  height: auto;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  position: absolute;
  top: -20px;
  left: 90%;
  z-index: 3;
  width: 350px;
  max-height: 600px;
  background-color: #5f5f5f;
  -webkit-box-shadow: 0px 2px 18px rgba(0, 0, 0, 0.34);
  box-shadow: 0px 2px 18px rgba(0, 0, 0, 0.34);
  border-radius: 5px;
  text-align: center;
  color: #f1f3f3;
  font-size: calc(12px + var(--font-increase-px));
  line-height: calc(14px + var(--font-increase-px));
  margin-bottom: 50px;
}
.instructor-slide .slider ul > li > a,
.instructor-slide .slider ul > li > div {
  display: block;
  color: #ffffff;
  font-size: calc(14px + var(--font-increase-px));
  line-height: calc(19px + var(--font-increase-px));
  text-decoration: none;
  padding: 0.75rem 1rem;
}
.instructor-slide .slider ul > li.active {
  background-color: #5b6b6e;
  border-radius: 5px;
}
.instructor-slide .slider ul > li.active a {
  color: #000;
}
.instructor-slide .slider ul > li:hover,
.instructor-slide .slider ul > li.active:hover {
  background-color: #78bcb8;
  border-radius: 5px;
}
.instructor-slide .slider ul > li:hover a {
  color: #ffffff;
}
.instructor-slide .slider ul > li:hover .info,
.instructor-slide .slider ul > li .info:hover {
  opacity: 1;
  visibility: visible;
}

.instructor-slide.false .slider ul > li .info {
  opacity: 0;
  visibility: hidden;
}

.instructor-slide .arrow-icon {
  background-color: #3b5d5b;
  border-radius: 0;
  padding: 0.85rem 0.313rem;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  border-radius: 0% 100% 100% 0% / 100% 25% 22% 0%;
}
.instructor-slide .info .user-name {
  font-size: calc(15px + var(--font-increase-px));
  line-height: calc(20px + var(--font-increase-px));
  display: inline-block;
  margin: 1rem 0;
}
.instructor-slide .info:before {
  content: "";
  display: block;
  border-right: 10px solid #5f5f5f;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  position: absolute;
  right: 100%;
  top: 32px;
}
.instructor-slide .arrow-icon .do-icon-white-arrow {
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  transform: rotate(180deg);
}
.instructor-slide.on .arrow-icon .do-icon-white-arrow {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(0deg);
}
.instructor-slide.on .slider {
  display: block;
}

.max-h-300 {
  max-height: 300px;
  overflow: auto;
  padding: 1.5rem 1.3rem 0.5rem;
}
.info-content {
  height: 100%;
  overflow: auto;
  padding: 1.3rem 0 0.5rem;
}
.info-content p {
  word-break: break-word;
}

.course_download {
  margin: 0 0 20px 30px;
  z-index: 1;
  position: relative;
}
.course_download a i {
  color: #3b5d5b;
}
.course_download a {
  color: #7a8689;
}

@media only screen and (max-width: 768px) {
  .instructor-slide .slider ul > li .info {
    width: 260px;
  }
}

@media only screen and (max-width: 576px) {
  .padding-bottom {
    padding-bottom: 6rem;
  }
}
