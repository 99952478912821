.MathType {
  width: 100%;
  height: 300px;
  opacity: 0;
}
.MathType.ready {
  opacity: 1;
}
.MathType.error {
  height: 0;
  overflow: hidden;
}

.spacing {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.MathType-Error {
  width: 100%;
  border-radius: 3px;
  padding: 10px 20px;
  background-color: #122529;
  color: white;
  text-align: center;
  font-weight: 300;
}

/* Hide info link to external Wiris site */
.MathType .wrs_linksContainer {
  display: none !important;
}

/* Header buttons */
.MathType .wrs_header.wrs_header.wrs_header.wrs_header.wrs_header > button {
  background-color: #122529 !important;
  background-image: none !important;
  border: none !important;
}

.MathType .wrs_header::-webkit-scrollbar {
  display: none;
}

.MathType .wrs_header {
  overflow-x: scroll !important;
}

.MathType [title="Big operators tab (Alt+8)"] .wrs_image {
  margin-left: -848px !important;
  margin-top: -205px !important;
}

.MathType
  .wrs_header.wrs_header.wrs_header.wrs_header.wrs_header
  > button.wrs_selected {
  background-color: #316570 !important;
}

/* Panel and buttons */
.MathType .wrs_panelContainer {
  border: 0 !important;
  background-color: black !important;
  background: black !important;
  font-size: 0 !important;
  line-height: 0 !important;
  overflow: auto !important;
}

.MathType .wrs_panelContainer::-webkit-scrollbar {
  height: 0px !important;
}
/* Buttons */
.MathType .wrs_panelContainer td {
  position: relative !important;
  border-radius: 3px !important;
  border: 2px solid black !important;
  width: unset !important;
  height: unset !important;
  overflow: hidden !important;
  background-color: black !important;
}
.MathType .wrs_panelContainer td button {
  position: relative !important;
  overflow: unset !important;
  background-color: #22454c !important;
  outline: none !important;
  overflow: hidden !important;
}
.MathType
  .wrs_panelContainer
  td
  button.wrs_disabled.wrs_disabled.wrs_disabled.wrs_disabled.wrs_disabled {
  background-color: #122529 !important;
  border-color: #122529 !important;
  pointer-events: none !important;
}
.MathType
  .wrs_panelContainer
  td
  button.wrs_disabled.wrs_disabled.wrs_disabled.wrs_disabled.wrs_disabled::before,
.MathType
  .wrs_panelContainer
  td
  button.wrs_disabled.wrs_disabled.wrs_disabled.wrs_disabled.wrs_disabled::after {
  background-color: #122529 !important;
}
.MathType .wrs_panelContainer td button:hover {
  background-color: #316570 !important;
}

/*
 * Add overlay for button, to hide overflow image
 *  in case we set a fixed width for the button
 * However it will hide the image with yellow border
 */
.MathType .wrs_panelContainer td button:hover::before,
.MathType .wrs_panelContainer td button:hover::after {
  /* content: ''; */
  background-color: #316570 !important;
}
.MathType .wrs_panelContainer td button::before,
.MathType .wrs_panelContainer td button::after {
  position: absolute;
  top: -10px;
  bottom: -10px;
  width: 50px;
  background-color: #22454c;
  z-index: 1;
}
.MathType .wrs_panelContainer td button::before {
  right: 100%;
}
.MathType .wrs_panelContainer td button::after {
  left: 100%;
}

/* Adjust spacing between buttons and border */
.MathType .wrs_panelContainer table {
  margin-right: 0 !important;
}
.MathType .wrs_panelContainer .wrs_layoutFor2Rows {
  border-top: 2px solid black !important;
  overflow: inherit !important;
}
.MathType .wrs_panelContainer tr:first-child td {
  border-top-width: 0 !important;
}
.MathType .wrs_panelContainer tr:last-child td {
  border-bottom-width: 0 !important;
}

/* Line */
.MathType .wrs_panelContainer .wrs_line {
  margin: 0 2px !important;
  width: 1px !important;
  min-width: 1px !important;
  height: auto !important;
  min-height: auto !important;
  background-color: #666666 !important;
}

/* Expand button */
.MathType .wrs_panelContainer .wrs_expandButton {
  margin: 0 2px !important;
  padding-bottom: 40px !important;
  width: 10px !important;
  min-width: 10px !important;
  height: auto !important;
  min-height: auto !important;
  background-color: #22454c !important;
}
.MathType .wrs_panelContainer .wrs_expandButton:hover {
  background-color: #316570 !important;
}

/* Panel spacing in toolbar */
.MathType .wrs_toolbar .wrs_panelContainer {
  padding: 6px 0 3px !important;
}

/* Adjust spacing between buttons and border */
.MathType .wrs_toolbar .wrs_panelContainer table:first-child td:first-child {
  border-left-width: 0 !important;
}
.MathType .wrs_multipleRowPanel.wrs_selected {
  display: flex !important;
  height: auto !important;
  width: fit-content !important;
}

/* Dropdown context panel (when click on the expand button) */
.MathType .wrs_contextPanel {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5) !important;
  @media (max-width: 768px) {
    width: 100% !important;
    height: auto !important;
    left: 0 !important;
  }
}
.MathType .wrs_contextPanel .wrs_panelContainer table {
  border: 2px solid black !important;
  border-left-width: 0 !important;
  border-right-width: 0 !important;
}

/* Fix style for the input area */
.MathType .wrs_formulaDisplay {
  border: 0 !important;
  border-radius: 3px !important;
  background-color: #122529 !important;
}
.MathType
  .wrs_formulaDisplay
  .wrs_selection.wrs_selection.wrs_selection.wrs_selection.wrs_selection {
  background-color: #316570 !important;
}

/* Fix matrix select dropdown style */
.MathType .wrs_matrixButtonPanel {
  background-color: black !important;
  border: none !important;
  border-radius: 3px !important;
  padding: 4px !important;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5) !important;
}
.MathType .wrs_matrixButtonPanel table:first-child td {
  height: 25px !important;
  border-radius: 3px !important;
  background-color: #22454c !important;
  border-color: black !important;
}
.MathType .wrs_matrixButtonPanel table:first-child td:first-child {
  border-left-width: 0 !important;
}
.MathType .wrs_matrixButtonPanel table:first-child td:last-child {
  border-right-width: 0 !important;
}
.MathType .wrs_matrixButtonPanel table:first-child .wrs_matrixButton {
  border-radius: 3px !important;
}
.MathType
  .wrs_matrixButtonPanel
  table:first-child
  .wrs_matrixButton.wrs_selected.wrs_selected.wrs_selected.wrs_selected.wrs_selected {
  background-color: #316570 !important;
  border-color: transparent !important;
}
.MathType .wrs_matrixButtonPanel table:last-child {
  width: 100% !important;
}
.MathType .wrs_matrixButtonPanel table:last-child td {
  border: none !important;
  border-radius: 1px !important;
  padding: 2px 5px !important;
  height: unset !important;
  background-color: black !important;
}
.MathType .wrs_matrixButtonPanel table:last-child td:last-child {
  width: 25px;
}
.MathType .wrs_matrixButtonPanel table:last-child input {
  border: none !important;
  border-radius: 3px !important;
  padding: 3px 0 !important;
}

/* Fix spacing dropdown style in the last tab */
.MathType .wrs_select {
  margin: 0 !important;
  border: none !important;
  border-radius: 3px !important;
  padding: 3px 0 !important;
  height: 22px !important;
  background-color: #22454c !important;
}
.MathType .wrs_select.wrs_withIcon {
  padding-top: 2px !important;
}
.MathType .wrs_selectPanel {
  border: none !important;
  border-radius: 3px !important;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5) !important;
  overflow: hidden !important;
}
.MathType .wrs_selectPanel > div {
  padding: 2px 5px !important;
  text-align: center !important;
}
.MathType .wrs_selectPanel.wrs_toolbarElement_setColumnSpacing > div,
.MathType .wrs_selectPanel.wrs_toolbarElement_setRowSpacing > div {
  width: 33.33333% !important;
}
.MathType
  .wrs_selectPanel.wrs_toolbarElement_setColumnSpacing
  > div:first-child,
.MathType .wrs_selectPanel.wrs_toolbarElement_setRowSpacing > div:first-child {
  width: 100% !important;
  text-align: center !important;
}

/* Make the height of the editor fixed */
.MathType .wrs_toolbar.wrs_toolbar.wrs_toolbar.wrs_toolbar.wrs_toolbar {
  height: 108px !important; /* plus 2px of margin-bottom = 110px */
}
.MathType .wrs_formulaDisplay,
.MathType .wrs_focusElementContainer {
  height: 190px !important; /* 300px - 110px */
}
.MathType .wrs_handWrapper input {
  height: 190px !important;
  border: none !important;
  border-radius: 3px !important;
}
.MathType *::-webkit-scrollbar {
  width: 0 !important; /* Hide vertical scrollbar but still able to scroll */
}

/* Fix UI line issue discovered by Joyce
   This could be a bug from MathType css because it only happens on first load
   If we open the hand then close it, it wont happen anymore */
.MathType .wrs_handContainer {
  visibility: hidden !important;
}
.MathType .wrs_handOpen .wrs_handContainer {
  visibility: visible !important;
}

/* Hide tabs and button groups as request from Mike */
.MathType .wrs_multipleRowPanel:nth-child(1) > *:nth-child(14),
.MathType .wrs_multipleRowPanel:nth-child(1) > *:nth-child(15),
.MathType .wrs_multipleRowPanel:nth-child(1) > *:nth-child(16),
.MathType .wrs_multipleRowPanel:nth-child(1) > *:nth-child(17),
.MathType .wrs_multipleRowPanel:nth-child(1) > *:nth-child(18),
.MathType .wrs_multipleRowPanel:nth-child(1) > *:nth-child(19) {
  display: none !important;
}
.MathType .wrs_multipleRowPanel:nth-child(2) > *:nth-child(1),
.MathType .wrs_multipleRowPanel:nth-child(2) > *:nth-child(2),
.MathType .wrs_multipleRowPanel:nth-child(2) > *:nth-child(7),
.MathType .wrs_multipleRowPanel:nth-child(2) > *:nth-child(17),
.MathType .wrs_multipleRowPanel:nth-child(2) > *:nth-child(18),
.MathType .wrs_multipleRowPanel:nth-child(2) > *:nth-child(19),
.MathType .wrs_multipleRowPanel:nth-child(2) > *:nth-child(20),
.MathType .wrs_multipleRowPanel:nth-child(2) > *:nth-child(21),
.MathType .wrs_multipleRowPanel:nth-child(2) > *:nth-child(22),
.MathType .wrs_multipleRowPanel:nth-child(2) > *:nth-child(23),
.MathType .wrs_multipleRowPanel:nth-child(2) > *:nth-child(24),
.MathType .wrs_multipleRowPanel:nth-child(2) > *:nth-child(25),
.MathType .wrs_multipleRowPanel:nth-child(2) > *:nth-child(26),
.MathType .wrs_multipleRowPanel:nth-child(2) > *:nth-child(27),
.MathType .wrs_multipleRowPanel:nth-child(2) > *:nth-child(28),
.MathType .wrs_multipleRowPanel:nth-child(2) > *:nth-child(29),
.MathType .wrs_multipleRowPanel:nth-child(2) > *:nth-child(30) {
  display: none !important;
}
.MathType
  .wrs_multipleRowPanel:nth-child(3)
  > *:nth-child(1)
  tr:nth-child(1)
  td:nth-child(3),
.MathType .wrs_multipleRowPanel:nth-child(3) > *:nth-child(1) tr:nth-child(2),
.MathType .wrs_multipleRowPanel:nth-child(3) > *:nth-child(1) tr:nth-child(3),
.MathType .wrs_multipleRowPanel:nth-child(3) > *:nth-child(2),
.MathType .wrs_multipleRowPanel:nth-child(3) > *:nth-child(3),
.MathType .wrs_multipleRowPanel:nth-child(3) > *:nth-child(4),
.MathType .wrs_multipleRowPanel:nth-child(3) > *:nth-child(5),
.MathType .wrs_multipleRowPanel:nth-child(3) > *:nth-child(6),
.MathType .wrs_multipleRowPanel:nth-child(3) > *:nth-child(7),
.MathType .wrs_multipleRowPanel:nth-child(3) > *:nth-child(8),
.MathType .wrs_multipleRowPanel:nth-child(3) > *:nth-child(9),
.MathType .wrs_multipleRowPanel:nth-child(3) > *:nth-child(10),
.MathType .wrs_multipleRowPanel:nth-child(3) > *:nth-child(11) {
  display: none !important;
}
.MathType .wrs_multipleRowPanel:nth-child(4) > *:nth-child(6),
.MathType .wrs_multipleRowPanel:nth-child(4) > *:nth-child(7),
.MathType .wrs_multipleRowPanel:nth-child(4) > *:nth-child(8),
.MathType .wrs_multipleRowPanel:nth-child(4) > *:nth-child(9),
.MathType .wrs_multipleRowPanel:nth-child(4) > *:nth-child(10),
.MathType .wrs_multipleRowPanel:nth-child(4) > *:nth-child(11),
.MathType .wrs_multipleRowPanel:nth-child(4) > *:nth-child(12),
.MathType .wrs_multipleRowPanel:nth-child(4) > *:nth-child(13),
.MathType .wrs_multipleRowPanel:nth-child(4) > *:nth-child(14),
.MathType .wrs_multipleRowPanel:nth-child(4) > *:nth-child(15),
.MathType .wrs_multipleRowPanel:nth-child(4) > *:nth-child(16) {
  display: none !important;
}
.MathType .wrs_multipleRowPanel:nth-child(5) > *:nth-child(6),
.MathType .wrs_multipleRowPanel:nth-child(5) > *:nth-child(7),
.MathType .wrs_multipleRowPanel:nth-child(5) > *:nth-child(8),
.MathType .wrs_multipleRowPanel:nth-child(5) > *:nth-child(9),
.MathType .wrs_multipleRowPanel:nth-child(5) > *:nth-child(10) {
  display: none !important;
}
.MathType .wrs_multipleRowPanel:nth-child(6) > *:nth-child(6),
.MathType .wrs_multipleRowPanel:nth-child(6) > *:nth-child(7),
.MathType .wrs_multipleRowPanel:nth-child(6) > *:nth-child(8),
.MathType .wrs_multipleRowPanel:nth-child(6) > *:nth-child(9),
.MathType .wrs_multipleRowPanel:nth-child(6) > *:nth-child(12),
.MathType .wrs_multipleRowPanel:nth-child(6) > *:nth-child(13) {
  display: none !important;
}
.MathType .wrs_multipleRowPanel:nth-child(8) > *:nth-child(2),
.MathType .wrs_multipleRowPanel:nth-child(8) > *:nth-child(3),
.MathType .wrs_multipleRowPanel:nth-child(8) > *:nth-child(6),
.MathType .wrs_multipleRowPanel:nth-child(8) > *:nth-child(7) {
  display: none !important;
}
.MathType .wrs_multipleRowPanel:nth-child(9) > *:nth-child(6),
.MathType .wrs_multipleRowPanel:nth-child(9) > *:nth-child(7) {
  display: none !important;
}
.MathType .wrs_header button:nth-child(7) {
  display: none !important;
}
.MathType .wrs_hand .wrs_toolbar > .wrs_button:last-child {
  display: none !important;
}
