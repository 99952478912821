.registration-page__header {
  margin: 5rem 0 3rem;
  font-size: 38px;
  font-weight: 300;
  line-height: 1rem;
  letter-spacing: 0.3rem;
  text-align: center;
  text-transform: uppercase;
}

.typeform-error {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

#footer {
  margin-top: 70px;
  display: flex;
  justify-content: center;
}

#footer-content {
  text-align: center;
  max-width: 650px;
}

#footer-text {
  margin-bottom: 20px;
  font-size: calc(1rem + var(--font-increase-rem));
  font-weight: 300;
  -webkit-font-smoothing: auto;
}

#embedded-typeform {
  height: 770px;
  margin: 0 15rem 2rem;
}

@media only screen and (max-width: 920px) {
  #embedded-typeform {
    margin: 0 10rem;
  }

  #footer-content {
    max-width: 500px;
  }
}

@media only screen and (max-width: 760px) {
  #embedded-typeform {
    margin: 0 5rem;
  }
}

@media only screen and (max-width: 600px) {
  #footer-content {
    max-width: 350px;
  }
}

@media only screen and (max-width: 480px) {
  #embedded-typeform {
    margin: 0 1rem;
  }
}
