.gradesHeading {
  font-size: 2.5rem;
  margin-top: 58px;
  font-weight: 300;
}

.gradeTableHeaders {
  color: white !important;
  font-weight: 300;
}

.gradeSearchRightAligned {
  display: grid;
  justify-items: right;
}

.gradeSearchBox {
  border-radius: 7px;
  margin-bottom: 20px;
  padding: 5px;
  font-size: 1.1rem;
}

.studentIdRow {
  background-color: rgba(13, 14, 14, 0.876);
}

.gradesRecordsPerPage {
  width: 60px;
  padding-left: 10px;
  height: 40px;
  background: #161618;
  color: white;
  border-radius: 7px;
  font-size: 1.1rem;
}

.gradesRow {
  font-size: 1.1rem;
  line-height: 1.25rem;
  -webkit-font-smoothing: antialiased;
}

.gradesFooter {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.gradesPagination {
  border: 1px solid rgb(169, 169, 169);
  width: 60px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.1rem;
}

.activePagination {
  font-weight: 900;
  background-color: #78bcb8;
}

.gradesPaginationActiveLinkClass {
  color: white;
}

.gradesPaginationFirstElement {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  align-items: flex-end;
  font-size: 1.75rem;
}

.gradesPaginationLastElement {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  align-items: flex-end;
  font-size: 1.75rem;
}

.gradesPaginationNext,
.gradesPaginationPrev {
  font-size: 1.5rem;
}

.hideGradesFooter {
  display: none;
}

.gradeDisablePagiPrevNext a:hover {
  text-decoration: none;
  cursor: text !important;
  color: #78bcb8 !important;
}
