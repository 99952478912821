.quizlet-wrapper {
  width: 78%;
  margin: auto;
  margin-bottom: 30px;
}

.quizlet-iframe {
  height: 90vh;
  width: 100%;
  border: 0;
  filter: invert(10%) grayscale(100%);
}
