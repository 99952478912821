.do-icon-download {
  width: 19px;
  height: 12px;
}
.do-icon-download {
  background-image: url(/images/icons/icon-download.svg);
}
.do-icon-play {
  width: 29px;
  height: 31px;
}
.do-icon-play {
  background-image: url(/images/icons/icon-play.svg);
}
.do-icon-thumbsup {
  width: 22px;
  height: 20px;
}
.do-icon-thumbsup {
  background-image: url(/images/icons/icon-thumbsup.svg);
}
.do-icon-star,
.do-icon-star-filled {
  width: 17.91px;
  height: 17px;
}
.do-icon-star {
  background-image: url(/images/icons/icon-star.svg);
}
.do-icon-star-filled,
.do-icon-star.filled {
  background-image: url(/images/icons/icon-star-filled.svg);
}

.separator {
  border-top: 1px solid rgba(255, 255, 255, 0.27);
}
.btn.btn-feedback {
  font-size: calc(12px + var(--font-increase-px));
  font-stretch: normal;
  line-height: calc(20px + var(--font-increase-px));
  color: #6f6f6f;
}

/*----- style.css end---- */

/*---- lecture-video css ---- */
.downloads {
  position: relative;
  z-index: 1;
}
.downloads a {
  color: #7a8689;
  font-size: calc(12px + var(--font-increase-px));
  line-height: calc(14px + var(--font-increase-px));
  text-decoration: none;
}
.video-feedback {
  position: absolute;
  width: 100%;
  height: auto;
  bottom: 0;
  z-index: 2;
  font-size: calc(15px + var(--font-increase-px));
  line-height: calc(18px + var(--font-increase-px));
}
.video-overlap:before {
  content: "";
  background-color: rgba(0, 0, 0, 0.62);
  z-index: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
}
.play-btn {
  position: absolute;
  z-index: 2;
  top: calc(50% - 58px);
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 0.94rem 2rem 0.94rem 2.8rem;
  border-radius: 24px;
}

/* "Swap Instructors" label to highlight the slide out tab */

/* Rotated elements are typically difficult to position because they keep their
 * original height and width. This container fixes that.
 * The container takes up no space so it can use relative positioning, but
 * without changing the document flow. If you need to change the position of the
 * rotated child, do it here because things like "top" and "left" will be
 * relative to the parent container and should just work.
 * The top offset of 44px comes from a hard-coded height of a div in
 * CourseMaterialDownload.js.
 */
.swap-instructor-container {
  height: 0px;
  width: 0px;
  position: relative;
  top: 44px;
}

/* Using transform origin allows us to correct the position of this element.
 * You may have to change the height and width of the element if the font size
 * or wording changes. Just make sure the top value is always the exact
 * negative of the height. And the left value is the exact negative of the
 * width. Do not try to change the position of this element. Use the container
 * to do that.
 */
.swap-instructor {
  transform: rotate(270deg);
  transform-origin: bottom right;
  height: 30px;
  width: 120px;
  color: #ffffff;
  position: absolute;
  top: -30px;
  left: -120px;
  font-size: 1rem;
}
