.pwa-form {
  margin-top: 70px;
  display: flex;
  justify-content: center;
}

.pwa-form-content {
  text-align: center;
  max-width: 650px;
}

.pwa-form-text {
  margin-bottom: 20px;
  font-size: calc(1rem + var(--font-increase-rem));
  font-weight: 300;
  -webkit-font-smoothing: auto;
}

@media only screen and (max-width: 920px) {
  .pwa-form-content {
    max-width: 500px;
  }
}

@media only screen and (max-width: 600px) {
  .pwa-form-content {
    max-width: 350px;
  }
}
