.column-freeze td:first-child {
  position: sticky;
  left: 0;
  z-index: 100 !important;
  background-color: #121212;
}

.column-freeze tr:first-child td:first-child {
  z-index: 101 !important;
}

.column-freeze th:first-child {
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: #121212;
}

.column-freeze td:first-child::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  border-right: 2px solid #98a4ae;
}

.column-freeze th:first-child::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  border-right: 2px solid #98a4ae;
}

.column-freeze td:first-child::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  border-left: 2px solid #98a4ae;
}

.column-freeze th:first-child::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  border-left: 2px solid #98a4ae;
}

.row-freeze tbody:first-child tr:first-child td {
  position: sticky;
  top: 0;
  z-index: 99;
  background-color: #121212;
}

.row-freeze tbody:first-child tr:first-child td::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-bottom: 2px solid #98a4ae;
}

.row-freeze tbody:first-child tr:first-child th::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-bottom: 2px solid #98a4ae;
}

.row-freeze tbody:first-child tr:first-child td::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border-top: 2px solid #98a4ae;
}

.row-freeze tbody:first-child tr:first-child th::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border-top: 2px solid #98a4ae;
}
