.component__backdrop {
  background-color: rgba(0, 0, 0, 0.37);
  -webkit-border-radius: 8px;
  border-radius: 8px 8px 0px 0px;
  padding: 36px;
}

.do-icon-white-arrow {
  width: 7px;
  height: 14px;
}
.do-icon-white-arrow {
  background-image: url(/images/icons/icon-white-arrow.svg);
}
.lesson .arrow-icon .do-icon-white-arrow {
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.lesson.on .arrow-icon .do-icon-white-arrow {
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}
.lesson .arrow-icon {
  background-color: #091214;
  border-radius: 0;
  padding: 1.6rem 0.6rem;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.lesson.on {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

.lesson-list .answer {
  background: #122529;
  -webkit-border-radius: 8px;
  border-radius: 8px;
}

.radio__badge.radio__badge-incorrect {
  background-color: #e1774f;
  position: relative;
  font-size: 0;
}

.radio__badge.radio__badge-correct {
  background-color: #78bcb8;
  position: relative;
  font-size: 0;
}

.radio__badge {
  width: 27px;
  height: 27px;
  background-color: #5b6b6e;
  border-radius: 100%;
  font-size: calc(9px + var(--font-increase-px));
  padding: 0;
  text-align: center;
  line-height: calc(27px + var(--font-increase-px));
  vertical-align: middle;
}

.radio__badge.radio__badge-md {
  width: 33px;
  height: 33px;
  line-height: 33px;
}

.radio__badge.radio__badge-xs {
  width: 20px;
  height: 20px;
  line-height: 20px;
}

.radio__badge.radio__badge-incorrect::after {
  background-color: #e1774f;
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  background-repeat: no-repeat;
  background-color: #ffffff;
  -webkit-mask: url(/images/icons/icon-cross-sm.svg) no-repeat 6px 6px;
  mask: url(/images/icons/icon-cross-sm.svg) no-repeat 6px 6px;
}

.radio__badge.radio__badge-md.radio__badge-incorrect:after {
  -webkit-mask: url(/images/icons/icon-cross-md.svg) no-repeat 5px 5px;
  mask: url(/images/icons/icon-cross-md.svg) no-repeat 5px 5px;
}
.radio__badge.radio__badge-xs.radio__badge-incorrect:after {
  -webkit-mask: url(/images/icons/icon-cross-md.svg) no-repeat 3px 3px;
  mask: url(/images/icons/icon-cross-md.svg) no-repeat 3px 3px;
  mask-size: 13.5px 14.5px;
}

.radio__badge.radio__badge-correct::after {
  background-color: #e1774f;
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  background-repeat: no-repeat;
  background-color: #ffffff;
  -webkit-mask: url(/images/icons/icon-checkmark.svg) no-repeat 7px 9px;
  mask: url(/images/icons/icon-checkmark.svg) no-repeat 7px 9px;
}
.radio__badge.radio__badge-md.radio__badge-correct:after {
  -webkit-mask: url(/images/icons/icon-checkmark-md.svg) no-repeat 6px 9px;
  mask: url(/images/icons/icon-checkmark-md.svg) no-repeat 6px 9px;
}
.radio__badge-sm.radio__badge-correct::after {
  top: -5px;
  left: -5px;
}

.radio__badge.radio__badge-xs.radio__badge-correct:after {
  -webkit-mask: url(/images/icons/icon-checkmark-md.svg) no-repeat 4.5px 5px;
  mask: url(/images/icons/icon-checkmark-md.svg) no-repeat 4.5px 5px;
  mask-size: 10.5px 10.7px;
}
.lesson .view-lesson {
  background-color: #122529;
  position: absolute;
  bottom: 0;
  width: calc(100% - 28px);
  left: 0;
  border-bottom-right-radius: 8px;
}
.lesson .view-lesson .btn {
  font-size: calc(13px + var(--font-increase-px));
  line-height: calc(35px + var(--font-increase-px));
  padding: 0px 8px;
  width: 135px;
}

.wrs_viewer {
  background: #fff !important;
}

.bubble_border {
  border-radius: 10px;
  border: 3px solid #fff;
}

.bubble_border.completed,
.bubble_border.incorrect {
  border: 2px solid #fff;
}

.bubble_star {
  background: #969640 !important;
}

.bubble_star:after {
  position: absolute;
  left: 0px;
  top: 2px;
  content: "";
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #ffffff;
  -webkit-mask: url("/images/icons/icon-star-filled.svg") no-repeat 1px 0;
  mask: url("/images/icons/icon-star-filled.svg") no-repeat 1px 0;
}

.bubble_star_big {
  background: #78bcb8 !important;
}

.bubble_star_big:after {
  position: absolute;
  left: 1px;
  top: 3px;
  content: "";
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #ffffff;
  -webkit-mask: url("/images/icons/icon-star-filled.svg") no-repeat 1px 0;
  mask: url("/images/icons/icon-star-filled.svg") no-repeat 1px 0;
}
.problem-bank .active__learning-blank {
  color: #fff;
  border-bottom: 1px solid #78bcb8;
}
.problem-bank input.active__learning-blank:-internal-autofill-selected,
.problem-bank input.active__learning-blank:-internal-autofill-selected:hover,
.problem-bank input.active__learning-blank:-internal-autofill-selected:focus,
.problem-bank input.active__learning-blank:-internal-autofill-selected:active {
  box-shadow: 0 0 0 30px #0e0e0f inset !important;
  -webkit-box-shadow: 0 0 0 30px #0e0e0f inset !important;
  -webkit-text-fill-color: #fff;
}
.active__learning-main-mid
  .active__learning-progress-track
  .btn.incorrect::before {
  content: none;
}

div#questiontext .wrs_viewer {
  background: transparent !important;
}
.active__learning-progress-track .progress__radio.bubble_border.active:before {
  top: 5px;
  left: 5px;
}

.active__learning-progress-track
  .progress__radio.bubble_border.completed:after {
  top: 4px;
  left: 1px;
}

.active__learning-progress-track
  .progress__radio.bubble_border.incorrect:after {
  top: 1px;
  left: 0px;
}

.star_text {
  font-size: calc(16px + var(--font-increase-px));
  color: #ffffff;
  line-height: calc(21px + var(--font-increase-px));
}

.wirisstudentanswer {
  position: relative;
  top: 0;
}

/* Add a right margin to the spinning icon */
.fa-margin {
  margin-left: -12px;
  margin-right: 8px;
}

input.wirisembeddedtextinput {
  width: 100% !important;
  background: black !important;
  height: 45px !important;
  font-size: 18px !important;
  padding-left: 15px !important;
  border: 1px solid #4c4c4c !important;
  box-sizing: border-box !important;
}

input.wirisembeddedtextinput:focus {
  border: 1px solid #77b1af !important;
  outline: none !important;
}

.plainTextInputHeading {
  margin-bottom: 8px;
}

.wirisanswerfielddecoration.wiriscorrect.wirisembeddeddecoration {
  width: 100% !important;
}

.wirisanswerfielddecoration.wirisincorrect.wirisembeddeddecoration {
  width: 100% !important;
}
