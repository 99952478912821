.terms-checkbox {
  height: 20px;
  width: 16px;
}
.agreement-text-container span {
  font-size: calc(10px + var(--font-increase-px));
}
.agreement-text-container a,
.agreement-text-container a:hover {
  vertical-align: unset;
  color: #78bcb8;
}
