.course-schedule h2 {
  font-size: calc(30px + var(--font-increase-px));
  line-height: calc(36px + var(--font-increase-px));
}

.course-schedule hr {
  display: block;
  margin: 20px 0 60px;
  background: #283538;
  height: 1px;
}

.course-schedule a {
  color: white;
  font-size: 1.5em;
}

.course-schedule-block {
  border-top: 2px solid rgba(142, 142, 142, 0.2);
  position: relative;
  border-top-right-radius: 13px;
  border-top-left-radius: 13px;
  overflow: hidden;
  margin-bottom: 50px;
  height: 100%;
  width: 100%;
}

.course-schedule-block > * {
  width: 100%;
}

.course-schedule-block:before,
.course-schedule-block:after {
  content: "";
  position: absolute;
  background-image: -webkit-gradient(
    linear,
    0 0,
    0 100%,
    from(#8e8e8e),
    to(transparent)
  );
  background-image: -webkit-linear-gradient(#8e8e8e, transparent);
  background-image: -moz-linear-gradient(#8e8e8e, transparent);
  background-image: -o-linear-gradient(#8e8e8e, transparent);
  background-image: linear-gradient(#8e8e8e, transparent);
  top: -1px;
  bottom: -1px;
  width: 2px;
  opacity: 0.2;
}

.course-schedule-block:before {
  left: 0;
}

.course-schedule-block:after {
  right: 0;
}

.course-schedule-head,
.course-schedule-body {
  padding: 20px;
}

.course-schedule-body {
  min-height: 290px;
  max-height: 310px;
  overflow: auto;
}

.course-schedule-body > * {
  width: 100%;
}

.course-schedule-head {
  min-height: 94px;
}

.course-schedule-head h3 {
  margin-bottom: 0;
  font-size: calc(20px + var(--font-increase-px));
  line-height: calc(26px + var(--font-increase-px));
}

.course-schedule-title {
  display: block;
  min-width: 100%;
  font-size: calc(16px + var(--font-increase-px));
  line-height: calc(20px + var(--font-increase-px));
}

.course-schedule-block hr {
  margin: 0;
  background: #283538;
  height: 2px;
}

.course-schedule-block ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.course-schedule-block li {
  padding: 10px 0;
}

.course-schedule-block li:first-child {
  padding-top: 0;
}

.course-schedule-block li:last-child {
  padding-bottom: 0;
}

.course-schedule-block .course-list-date {
  padding-left: 6px;
}

.course-schedule-block i,
.course-schedule-block .course-list-date {
  color: #78bcb8;
}

.course-schedule-midterm {
  text-align: center;
  background: #0c1a1d;
}

.midterm-icon i {
  color: #fff;
  font-size: calc(100px + var(--font-increase-px));
  text-align: center;
  display: block;
  padding: 30px 0 40px;
}

.back-to-home {
  left: calc(100% - 9rem);
  top: -2rem;
}
