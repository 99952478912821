.revealed-answer-box {
  flex: 0.95;
  line-height: 22px;
}

.answer-title {
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16.8px;
  letter-spacing: 1px;
  margin-bottom: 12px !important;
  color: #c1cfd9;
  text-transform: uppercase;
}

.answer-content {
  font-family: Lato;
  font-size: 18px !important;
  line-height: 22px !important;
}

.answer-status {
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #ffffff;
  margin-left: 12px;
}

.revealed-answer-box div.paragraph {
  margin-bottom: 0rem;
  font-size: 18px;
}

div.lesson-list.revealed-answer-wrapper {
  height: auto;
  width: auto;
  padding: 24px 4px 0px 4px !important;
  background-color: #0e0e0f;
}

@media only screen and (max-width: 1660px) {
  div.lesson-list.revealed-answer-wrapper {
    height: auto;
    max-height: 100%;
    width: auto;
    background-color: #0e0e0f;
  }
}

div.lesson-list.revealed-answer-wrapper div:last-child {
  margin-bottom: 0px !important;
}

div.lesson-list.revealed-answer-wrapper > div.solution-text {
  overflow: auto;
  width: inherit;
}

.match-text {
  color: #fff;
}
.explanation {
  margin-bottom: 24px;
  font-size: 18px;
  line-height: 21.6px;
}

.explanation div,
.explanation span {
  font-size: 18px;
  line-height: 21.6px;
  font-weight: 300 !important;
}
