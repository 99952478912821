.backdrop-modal {
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  z-index: -1;
  transition: all 0.5s ease-in-out;
}

.backdrop-modal.show {
  opacity: 1;
  z-index: 1044;
  overflow: scroll;
}

.wrapper-modal {
  background-color: #161618;
  position: absolute;
  top: 150px;
  left: 50%;
  width: 1080px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  transition: all 0.5s ease-in-out;
}

.wrapper-modal .img-modal {
  width: 100%;
}

.btn-close-modal {
  position: absolute;
  display: flex;
  justify-content: center;
  background-color: black;
  padding-top: 12px;
  top: -36px;
  right: -36px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.btn-close-modal:hover {
  background-color: #161618;
}

.btn-close-modal i {
  color: lightgray;
  font-size: 1.2rem;
}

@media only screen and (max-width: 1366px) {
  .wrapper-modal {
    width: 860px;
  }
}

@media only screen and (max-width: 960px) {
  .wrapper-modal {
    width: 90%;
  }
}
