.course-home__intro {
  padding-left: 30px;
  padding-right: 30px;
}

.course-home__intro .oval__container {
  width: 91px;
  height: 91px;
}

.oval__container.guesswork {
  background: #7f8e91;
}

.oval__container.guesswork img {
  width: 50px;
  height: 50px;
}

.cards {
  background: #1d1d1f;
  border-radius: 5px;
  height: 40px;
  margin-bottom: 12px;
  padding-right: 12px;
  padding-left: 12px;
}

@media (min-width: 576px) {
  .padding-right {
    padding-right: 0px;
    margin-right: 0px;
  }
  .padding-left {
    padding-left: 12px;
  }
}

.do-icon {
  display: inline-block;
  vertical-align: middle;
  background-repeat: no-repeat;
}

.do-icon-arrow-down {
  background-image: url("/images/icons/icon-angle-arrow-down.svg");
}

.do-icon-arrow-down {
  width: 15px;
  height: 8px;
}

.course-home .section-panel__btn .btn.course-resources-btn {
  font-size: 14px;
  line-height: 17px;
  color: #161618;
  width: 129px;
  height: 40px;
}

.course-home .section-panel__btn .btn.course-resources-btn:active {
  color: #77b1af;
}

.recText {
  font-style: normal;
}
