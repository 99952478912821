header {
  background-color: #000000;
  position: sticky;
  top: 0;
  z-index: 9;
}

header .navbar-title {
  font-size: calc(14px + var(--font-increase-px));
  line-height: calc(17px + var(--font-increase-px));
}

header .nav-item > a,
header .nav-item > a:hover,
header .nav-item > a:focus {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
header a,
header a:hover,
header a:focus {
  display: inline-block;
  color: #ffffff;
  text-decoration: none;
  vertical-align: middle;
}

header .nav-separator {
  -ms-grid-row-align: stretch;
  align-self: stretch;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  padding-top: 1px;
}

header .nav-text {
  font-size: calc(12px + var(--font-increase-px));
  line-height: calc(14px + var(--font-increase-px));
  font-weight: 500;
  padding-top: 1px;
}

header .nav-separator-bar:before {
  content: "";
  display: block;
  position: relative;
  width: 1px;
  height: 100%;
  border: 0;
  background-color: rgba(255, 255, 255, 0.44);
}

header .dropdown,
.nav-notification {
  position: relative;
}

header .outlier-header {
  margin-bottom: 4px !important;
}

.nav-notification .badge,
header .dropdown-profile .badge {
  min-width: 18px;
  min-height: 18px;
  line-height: calc(18px + var(--font-increase-px));
  text-align: center;
  padding: 0;
  font-size: calc(12px + var(--font-increase-px));
}

.nav-notification .badge {
  position: absolute;
  top: 55%;
  left: 80%;
}

header .dropdown-toggle {
  cursor: pointer;
}
header .dropdown-toggle:after {
  display: none;
}
header .navbar-nav .dropdown-menu {
  -webkit-transform: translateY(15px);
  -moz-transform: translateY(15px);
  -ms-transform: translateY(15px);
  -o-transform: translateY(15px);
  transform: translateY(15px);
  left: 10px;
}
header .navbar-nav svg {
  fill: #ffffff;
}
header .navbar-nav .dropdown-menu.dropdown-menu-right {
  left: auto;
  right: -15px;
}
header .dropdown-menu img {
  display: inline-block;
}
header .dropdown-menu {
  font-size: calc(10px + var(--font-increase-px));
  border-radius: 0 !important;
}
header .dropdown-menu:before {
  border-width: 0.8em;
}
header .dropdown-profile {
  min-width: 250px;
  font-size: calc(12px + var(--font-increase-px));
  line-height: calc(18px + var(--font-increase-px));
}
header .dropdown-profile a:last-child {
  padding-bottom: 16px;
}

header .dropdown-header {
  font-size: calc(9px + var(--font-increase-px));
}
header .menu-item {
  font-size: 16px;
  margin-left: 13px;
  line-height: 19px !important;
}

.header-top {
  background-color: #151518;
  margin-top: 80px;
  color: #ffffff;
  position: relative;
  font-size: calc(12px + var(--font-increase-px));
  padding: 20px 15px;
}

/*
.header-top:before {
    position: absolute;
    content: "";
    background: rgba(0,0,0,0.25);
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}
*/

.header-top a,
.header-top span {
  color: #ffffff;
  font-size: calc(14.6px + var(--font-increase-px));
}

.headertop-breadcrumb {
  text-align: center;
}

.headertop-breadcrumb a,
.headertop-breadcrumb span {
  color: #e7e7e7;
}
.headertop-breadcrumb svg.icon-angle-arrow:last-of-type,
.headertop-breadcrumb svg.icon-angle-arrow:last-of-type {
  display: none;
}
.headertop-breadcrumb a:last-of-type,
.headertop-breadcrumb span:last-of-type {
  text-decoration: none !important;
  color: #fff;
  cursor: default !important;
  pointer-events: none;
}
.header-top-left a svg {
  fill: #3b5d5b;
}

.header-top .headertop-breadcrumb svg {
  fill: #e7e7e7;
  margin-left: 5px;
  margin-right: 5px;
}

.dropdown-item {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
