.w-bottom-bar.w-css-reset {
  z-index: 1000 !important;
}

.mb-15 {
  margin-bottom: 15px;
}

.completed-btn {
  width: 230px;
  font-size: 16px;
}
